var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"divBox"},[_c('form-panel',_vm._b({ref:"formPanel",attrs:{"form":_vm.form,"submitBefore":_vm.submitBefore,"submitSuccess":_vm.submitSuccess},on:{"update":_vm.update}},'form-panel',_vm.submitConfig,false),[_c('el-form-item',{attrs:{"label":"话题标题","rules":[
        { required: true, message: '请输入话题标题', trigger: 'blur' },
      ],"prop":"topicTitle"}},[_c('v-input',{attrs:{"placeholder":"请输入话题标题","width":_vm.width},model:{value:(_vm.form.topicTitle),callback:function ($$v) {_vm.$set(_vm.form, "topicTitle", $$v)},expression:"form.topicTitle"}})],1),_c('el-form-item',{attrs:{"label":"副标题","rules":[{ required: false, message: '请输入副标题', trigger: 'blur' }],"prop":"nowStatus"}},[_c('v-input',{attrs:{"placeholder":"请输入副标题","width":_vm.width,"maxlength":12},model:{value:(_vm.form.nowStatus),callback:function ($$v) {_vm.$set(_vm.form, "nowStatus", $$v)},expression:"form.nowStatus"}})],1),_c('el-form-item',{attrs:{"label":"话题封面","rules":[
        {
          required: true,
          message: '请上传话题封面图片',
          trigger: ['change', 'blur'],
        },
      ],"prop":"topicPicture"}},[_c('v-upload',{attrs:{"limit":1,"imgUrls":_vm.form.topicPicture,"tips":"建议上传正方形图片"},on:{"update:imgUrls":function($event){return _vm.$set(_vm.form, "topicPicture", $event)},"update:img-urls":function($event){return _vm.$set(_vm.form, "topicPicture", $event)}}})],1),_c('el-form-item',{attrs:{"label":"关闭点赞","rules":[
        { required: true, message: '请选择是否关闭点赞', trigger: 'change' },
      ],"prop":"closePraise"}},[_c('v-select',{attrs:{"width":_vm.width,"options":_vm.isTopOps},model:{value:(_vm.form.closePraise),callback:function ($$v) {_vm.$set(_vm.form, "closePraise", $$v)},expression:"form.closePraise"}})],1),_c('el-form-item',{attrs:{"label":"关闭评论","rules":[
        { required: true, message: '请选择是否关闭评论', trigger: 'change' },
      ],"prop":"closeComment"}},[_c('v-select',{attrs:{"width":_vm.width,"options":_vm.isTopOps},model:{value:(_vm.form.closeComment),callback:function ($$v) {_vm.$set(_vm.form, "closeComment", $$v)},expression:"form.closeComment"}})],1),_c('el-form-item',{attrs:{"label":"是否置顶","rules":[
        { required: true, message: '请选择是否置顶', trigger: 'blur' },
      ],"prop":"isTop"}},[_c('v-select',{attrs:{"width":_vm.width,"options":_vm.isTopOps},model:{value:(_vm.form.isTop),callback:function ($$v) {_vm.$set(_vm.form, "isTop", $$v)},expression:"form.isTop"}})],1),(_vm.form.isTop === 1)?_c('el-form-item',{attrs:{"label":"置顶排序","rules":[
        {
          required: true,
          message: '请输入置顶排序值',
          trigger: ['blur', 'change'],
        },
        {
          message: '必须是数字格式',
          pattern: /^[0-9]+$/,
          trigger: 'blur',
        },
      ],"prop":"isTopSort"}},[_c('v-input',{attrs:{"placeholder":"请输入置顶排序值","min":"1","width":_vm.width,"type":"number"},model:{value:(_vm.form.isTopSort),callback:function ($$v) {_vm.$set(_vm.form, "isTopSort", $$v)},expression:"form.isTopSort"}})],1):_vm._e(),_c('el-form-item',{attrs:{"label":"话题内容","rules":[
        { required: true, message: '请输入话题内容', trigger: 'blur' },
      ],"prop":"topicContent"}},[_c('div',{attrs:{"id":"editor"}},[_c('v-ueditor',{attrs:{"maxLength":20000},model:{value:(_vm.form.topicContent),callback:function ($$v) {_vm.$set(_vm.form, "topicContent", $$v)},expression:"form.topicContent"}})],1)]),_c('el-form-item',{attrs:{"label":"发布状态","rules":[{ required: true, message: '请选择状态', trigger: 'blur' }],"prop":"status"}},[_c('v-select',{attrs:{"width":_vm.width,"options":_vm.statusOps},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}})],1),_c('el-form-item',{attrs:{"label":"备注","rules":[{ required: false, message: '请输入备注', trigger: 'blur' }],"prop":"remark"}},[_c('v-input',{attrs:{"placeholder":"备注","width":_vm.width},model:{value:(_vm.form.remark),callback:function ($$v) {_vm.$set(_vm.form, "remark", $$v)},expression:"form.remark"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }